import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';

const NewsNav = ({ actions, host, currentArticleUrl, currentTopic }) => {

    const handleSelect = async (eventKey) => {
        const DELETE = 2;

        if (eventKey === DELETE) {
            if (window.confirm(`Are you sure you want to delete?`)) {
                if (currentArticleUrl) {
                    await actions.deleteEmail(host, currentArticleUrl);
                } else if (currentTopic) {
                    await actions.deleteMailbox(host, currentTopic);
                }
                window.location.href = '/';
            }
        }
    }

    return (
        <Navbar inverse fixedTop collapseOnSelect>
            <Navbar.Header>
                <Navbar.Brand>
                    <Link to="/"><img src="/news-white-144px.png" alt="Lucas Mail" /></Link><Link to="/">Lucas Mail</Link>
                </Navbar.Brand>
                <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
                <Nav pullRight onSelect={(k) => handleSelect(k)}>
                    <NavItem href="/">
                        <span className='glyphicon glyphicon-home'></span>&nbsp;Home
                    </NavItem>
                    { (currentArticleUrl || (currentTopic && currentTopic !== 'news')) &&
                        <NavItem eventKey={2} href="#">
                            Delete
                        </NavItem>
                    }
                    {/* <NavItem href="/technology">
                        <span className='glyphicon glyphicon-th-list'></span>&nbsp;Technology
                    </NavItem>
                    <NavItem href="/hacker-news">
                        <span className='glyphicon glyphicon-menu-right'></span>&nbsp;Hacker News
                    </NavItem>
                    <NavItem href="/microsoft">
                        <span className='glyphicon glyphicon-menu-right'></span>&nbsp;Microsoft
                    </NavItem>
                    <NavItem href="/science">
                        <span className='glyphicon glyphicon-education'></span>&nbsp;Science
                    </NavItem>
                    <NavItem href="/sports">
                        <span className='glyphicon glyphicon-flag'></span>&nbsp;Sports
                    </NavItem>
                    <NavItem href="/afl">
                        <span className='glyphicon glyphicon-menu-right'></span>&nbsp;AFL
                    </NavItem>
                    <NavItem href="/australia-cricket">
                        <span className='glyphicon glyphicon-menu-right'></span>&nbsp;Australia Cricket
                    </NavItem>
                    <NavItem href="/thredbo">
                        <span className='glyphicon glyphicon-menu-right'></span>&nbsp;Thredbo
                    </NavItem> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
};

export default NewsNav
