export const ERROR = 'ERROR';

export const REQUEST_TOPIC = 'REQUEST_TOPIC';
export const RECEIVE_TOPIC = 'RECEIVE_TOPIC';

export const REQUEST_ARTICLE = 'REQUEST_ARTICLE';
export const RECEIVE_ARTICLE = 'RECEIVE_ARTICLE';

export const REMOVE_IMAGE = 'REMOVE_IMAGE';

export const REQUEST_ARTICLE_DELETE = 'REQUEST_ARTICLE_DELETE';
export const RECEIVE_ARTICLE_DELETE = 'RECEIVE_ARTICLE_DELETE';

export const REQUEST_TOPIC_DELETE = 'REQUEST_TOPIC_DELETE';
export const RECEIVE_TOPIC_DELETE = 'RECEIVE_TOPIC_DELETE';
